export const environment = {
	appVersion: require('../../package.json').version + '-acc',
	env: 'acc',
	hmr: false,
	debug: false,
	apiUrl: 'https://test.apollo.quickrelease.aero/graphql',
	wsUrl: 'wss://test.apollo.quickrelease.aero/graphql',
	baseHelpUrl: 'https://docs.quickrelease.aero/',
	production: false,
	identityPlatformUrl: 'https://identitytoolkit.googleapis.com/v1/',
	identityPlatformResetPasswordSuffix: 'accounts:sendOobCode?key=',
	identityPlatformSignInSuffix: 'accounts:signInWithPassword?key=',
	identityPlatformRefreshTokenSuffix: 'token?key=',
	identityPlatformApiKey: 'AIzaSyCh926bdSzZAytwpBLzAQXY3-OkFHVTpQo',
	upURL: 'https://test.up.quickrelease.aero/',
	icsUrl: 'https://calendar.quickrelease.aero/',
	// *************** TODO ****************** needs to be removed in a later stage
	firebase: {
		apiKey: 'AIzaSyCh926bdSzZAytwpBLzAQXY3-OkFHVTpQo',
		authDomain: 'qr-apollo-test.firebaseapp.com',
		projectId: 'qr-apollo-test',
		storageBucket: 'qr-apollo-test.appspot.com',
		messagingSenderId: '110746452687',
		appId: '1:110746452687:web:11ca819e22bce2a930edba',
		measurementId: 'G-ZTGDF4KN57',
	},
	helphero: {
		appId: '684NBa0mOcb',
	},
	trackingCode: 'G-ZTGDF4KN57',
	componentGuards: {
		dashboard: {
			meteo: [UserRole.Opr, UserRole.Met, UserRole.Ass, UserRole.Cre],
			flights: [UserRole.Opr, UserRole.Met, UserRole.Ass, UserRole.Cre],
			addFlights: [UserRole.Opr],
			bookings: [UserRole.Opr, UserRole.Ass],
			sms: [UserRole.Opr, UserRole.Ass],
			charts: [UserRole.Opr, UserRole.Cre],
		},
		dateCard: {
			plan: [UserRole.Opr],
		},
		flightCard: {
			edit: [UserRole.Opr, UserRole.Ass, UserRole.Met],
			checkIn: [UserRole.Opr, UserRole.Cre],
			bookings: [UserRole.Opr, UserRole.Ass],
		},
		flightList: {
			bookingsColumn: [UserRole.Opr, UserRole.Ass],
		},
		flightDetail: {
			crew: [UserRole.Opr, UserRole.Ass],
			bookings: [UserRole.Opr, UserRole.Ass],
			payments: [UserRole.Opr, UserRole.Ass],
			log: [UserRole.Opr, UserRole.Ass],
		},
		calendar: {
			event: [UserRole.Opr, UserRole.Ass],
			operator: [UserRole.Opr, UserRole.Ass, UserRole.Rep, UserRole.Met],
		},
		bookingDetail: {
			delete: [UserRole.Opr],
		},
		toolbar: {
			operator: [UserRole.Opr, UserRole.Ass],
		},
	},
};

import { UserRole } from 'src/app/core/graphql/generated/gen-types';
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
